<template>
  <div class="training-manage-notice">
    <table-list
      title="公告列表"
      :loading="loading"
      :data="dataList"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      :options="{ selection: true }"
      :tabs-list="tabsList"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '发布公告',
    method: _this.handleAdd,
  },
  {
    label: '删除',
    type: 'danger',
    method: _this.handleDelete,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'title',
    minWidth: 160,
    label: '公告标题',
    twoLines: true,
  },
  {
    prop: 'createTime',
    label: '发布时间',
    minWidth: 150,
  },
  {
    prop: 'pageViews',
    label: '浏览次数',
    minWidth: 82,
  },
  {
    prop: 'publishUserName',
    minWidth: 76,
    label: '发布人',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
]
import TableList from '@/components/TableList'
import { classCertAnnouncement, classCertAnnouncementDelete } from '@/api/certificate'
import to from 'await-to'
export default {
  name: 'TrainingManageNotice',
  components: {
    TableList,
  },
  data() {
    return {
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
      classCertId: '',
      btns,
      columns,
      operates,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      dataList: [],
      selectList: [],
    }
  },
  mounted() {
    this.classCertId = this.$route.query.classCertId
    this.getList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        classCertAnnouncement({
          current,
          size,
          ...this.queryParams,
          classCertId: this.classCertId,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.dataList = res.data.list
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getList()
    },
    async handleAdd() {
      this.$router.push(`/certificate/trainingManage/notice/add?classCertId=${this.classCertId}`)
    },
    handleEdit(row) {
      this.$router.push(
        `/certificate/trainingManage/notice/${row.id}?classCertId=${this.classCertId}`,
      )
    },
    handleDelete() {
      let ids = []
      this.selectList.map(item => ids.push(item.id))
      this.$confirm('您确定要删除吗？删除后将不可恢复', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(classCertAnnouncementDelete({ ids }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    handleDetail() {},
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
  },
}
</script>
